<template>
    <div class="user bg-secondary" :class="[initialised ? `initialised` : '']">
        <div class="login-form">
            <!--  <v-btn text class="zindex-2 mt-6" @click="goBack()" v-if="$vuetify.breakpoint.xsOnly">
            <v-icon>mdi-chevron-left</v-icon>
            Back
        </v-btn> -->
            <v-img :src="assets.user.userCircle" max-width="120" max-height="120" contain class="mx-auto mt-10"></v-img>
            <div class="dialog-row">
                <transition name="slide-y-reverse-transition" mode="out-in" appear>
                    <router-view class="mx-auto" />
                </transition>
            </div>
        </div>

        <!-- <div class="footer-row pa-5 mw-600 mx-auto">
            <p class="caption ma-0">&copy; 2022 Red Sparrow Books</p>
        </div> -->
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    components: {},
    data() {
        return {
            initialised: false,
        };
    },
    computed: {
        ...mapState(["assets"]),
        background() {
            // if (this.$router?.path?.includes("auth") || this.$router?.path?.includes("account")) {
            //     return "bg-blue";
            // } else if (this.$router?.path?.includes("home")) {
            //     return "bg-white";
            // } else {
            //     return "bg-blue";
            // }
            return "";
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
    created() {
        this.initialised = window.sessionStorage.initialised || false;

        // try {
        // 	let App = window.parent.Capacitor.Plugins.App
        // } catch (e) {
        // 	console.error("(App) Capacitor not found.")
        // 	this.initialised = true
        // }

        setTimeout(() => {
            this.initialised = true;
            window.sessionStorage.initialised = true;
        }, 3500);
    },
};
</script>

<style lang="scss">
.user {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form {
        min-width: 280px;
        width: 50%;
        max-width: 960px;
    }
}
</style>
